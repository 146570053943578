// const nepaliNumbers = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
const nepaliNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export function englishNumberConverter(numString) {
	let englishNumber = '';
	for (let i = 0; i < numString.length; i++) {
		let num = numString[i];
		let englishNum = nepaliNumbers.indexOf(num);
		if (englishNum < 0) {
			englishNumber += num;
		} else {
			englishNumber += englishNum;
		}
	}
	return englishNumber;
}

export function nepaliNumberConverter(numString) {
	let nepaliNumber = '';
	for (let i = 0; i < numString?.length; i++) {
		if (nepaliNumbers.includes(numString[i])) {
			nepaliNumber += numString[i];
		} else if (nepaliNumbers[numString[i]]) {
			nepaliNumber += nepaliNumbers[numString[i]];
		} else {
			nepaliNumber += numString[i];
		}
	}
	return nepaliNumber;
}
