const authProvider = {
	login: ({ username, password }) => {
		const request = new Request(
			`${process.env.REACT_APP_BACKEND_URL}/auth/login`,
			{
				method: 'POST',
				body: JSON.stringify({ email: username, password }),
				headers: { 'Content-Type': 'application/json' },
			}
		);
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ data }) => {
				localStorage.setItem('auth', JSON.stringify(data));
			})
			.catch((error) => {
				throw new Error('Incorrect username or password');
			});
	},
	logout: () => {
		localStorage.removeItem('auth');
		localStorage.removeItem('user');
		return Promise.resolve();
	},
	checkError: (error) => {
		const status = error.status;
		if (status === 401) {
			localStorage.removeItem('auth');
			return Promise.reject({
				redirectTo: '/login',
				logoutUser: true,
				message: 'Please Login',
			});
		}
		// other error code (404, 500, etc): no need to log out
		return Promise.resolve();
	},
	checkAuth: () =>
		localStorage.getItem('auth')
			? Promise.resolve()
			: Promise.reject({
					redirectTo: '/login',
					logoutUser: true,
					message: 'Please Login',
			  }),
	// {
	//   let auth;
	//   try {
	//     auth = JSON.parse(localStorage.getItem("auth"));
	//   } catch (e) {}

	//   if (auth) {
	//     if (new Date().getTime() > new Date(auth.accessTokenExpires).getTime())
	//       Promise.resolve();
	//     else
	//       Promise.reject({
	//         redirectTo: "/login",
	//         logoutUser: true,
	//         message: "Please Login",
	//       });
	//   } else {
	//     ;
	//   }
	// }
	getPermissions: () => {
		const user = JSON.parse(localStorage.getItem('user'));
		const permissions =
			user?.role_en === 'admin' ? { '*': 'draws' } : user.permission;
		return Promise.resolve(permissions);
	},
	// getPermissions: () =>
	// 	Promise.resolve([
	// 		{ action: ['read', 'create', 'edit', 'export'], resource: 'companies' },
	// 		{ action: ['read', 'create', 'edit'], resource: 'people' },
	// 		{ action: ['read', 'create', 'edit', 'export'], resource: 'deals' },
	// 		{ action: ['read', 'create'], resource: 'comments' },
	// 		,
	// 		{ action: ['read', 'create'], resource: 'tasks' },
	// 		{ action: ['write'], resource: 'tasks.completed' },
	// 	]),
	getIdentity: () => {
		const data = JSON.parse(localStorage.getItem('auth'));
		// Promise.resolve(data);

		const request = new Request(`${process.env.REACT_APP_BACKEND_URL}/me`, {
			method: 'GET',
			headers: { Authorization: 'Bearer ' + data?.access },
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ data }) => {
				localStorage.setItem('user', JSON.stringify(data));
				Promise.resolve(data);
			})
			.catch((error) => {
				// throw new Error('Failed to get User Info');
				localStorage.removeItem('auth');
				return Promise.reject({
					redirectTo: '/login',
					logoutUser: true,
					message: 'Please Login',
				});
			});
	},
};

export default authProvider;
